import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./navbar.css";
import navlogo from "../../assets/navbar/nav-logo.svg";
import { useLocation } from "react-router-dom";
import jQuery from "jquery";

function NavBar() {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash === "") {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          setTimeout(function () {
            element.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }, 100);
        }
      }, 100);
    }
  }, [hash]);

  useEffect(() => {
    function navHighlighter() {
      const sections = document.querySelectorAll("section[id]");
      let scrollY = window.pageYOffset;
      let homeLink = document.querySelector(".navbar-nav a[href*=homepage]");
      homeLink.classList.remove("active");

      sections.forEach((current) => {
        const sectionHeight = current.offsetHeight;
        const sectionTop = current.offsetTop - 70;
        let sectionId = current.getAttribute("id");

        if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
          if (sectionId === "header") {
            homeLink.classList.add("active");
          } else {
            const sectionLink = document.querySelector(
              `.navbar-nav a[href*=${sectionId}]`
            );
            if (sectionLink) {
              sectionLink.classList.add("active");
            }
          }
        } else {
          const sectionLink = document.querySelector(
            `.navbar-nav a[href*=${sectionId}]`
          );
          if (sectionLink) {
            sectionLink.classList.remove("active");
          }
        }
      });
    }

    window.addEventListener("scroll", navHighlighter);

    return () => {
      window.removeEventListener("scroll", navHighlighter);
    };
  }, []);

  return (
    <section id="navbar">
      <Navbar collapseOnSelect expand="lg" className="navbar">
        <Container>
          <Navbar.Brand href="/#homepage">
            <img src={navlogo} alt="logo" className="navbar-logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="navbar-nav ms-auto">
              <Nav.Link href="/#homepage" className="nav-link">
                Home
              </Nav.Link>
              <Nav.Link href="/#strength" className="nav-link">
                Our core strength
              </Nav.Link>
              <Nav.Link href="/#about" className="nav-link">
                About
              </Nav.Link>
              <Nav.Link href="/#study-destination" className="nav-link">
                Study Destinations
              </Nav.Link>
              <Nav.Link href="/#testimonials" className="nav-link">
                Testimonials
              </Nav.Link>
              <Nav.Link href="/#enquire" className="nav-link">
                Enquire
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </section>
  );
}

jQuery(function ($) {
  window.addEventListener("scroll", function () {
    let header = document.querySelector("nav");
    header.classList.toggle("nav-sticky", window.scrollY > 360);
  });
  // Code for changing active link on clicking
  var btns = $("navbar-nav > nav-link");
  if (btns.length != 0) {
    for (var i = 0; i < btns.length; i++) {
      btns[i].addEventListener("click", function () {
        var current = document.getElementsByClassName("active");

        current[0].className = current[0].className.replace(" active", "");
        this.className += " active";
      });
    }
  }

  //   $(document).ready(function () {
  //     $('.navbar-nav navLink a').click(function(e) {

  //         $('.navbar-nav navLink.active').removeClass('active');

  //         var $parent = $(this).parent();
  //         $parent.addClass('active');
  //         e.preventDefault();
  //     });
  // });
});

export default NavBar;
