import React from "react";
import "./header.css";
import { Container } from "react-bootstrap";
import Insta from "../../assets/header/instagram.svg";
import Fb from "../../assets/header/facebook.svg";
import utube from "../../assets/header/youtube.svg";

function Header() {
  return (
    <section id="header">
      <div className="header-bg">
        <Container>
          <div className="header-item">
            <div className="contact-number">
              <a href="tel:+91 9778286699">+91 97782 86699, </a>
              <a href="tel:+91 9995476762">+91 99954 76762</a>
            </div>
            <div className="header-media">
              <a
                href="https://instagram.com/pes_overseas?igshid=MzRlODBiNWFlZA=="
                class="float"
                target="_blank"
              >
                <img src={Insta} className="ms-3 in" alt="instagram" />
              </a>
              <a
                href="https://www.facebook.com/pesoverseas"
                class="float"
                target="_blank"
              >
                <img src={Fb} className="ms-3 in" alt="Facebook" />
              </a>
              <a href="https://www.youtube.com" class="float" target="_blank">
                <img src={utube} className="no in" alt="youtube" />
              </a>
            </div>
          </div>
        </Container>
      </div>
    </section>
  );
}

export default Header;
