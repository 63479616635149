import React from "react";
import { Container } from "react-bootstrap";
import banner from "../../assets/banner/banner.png";
import arrow from "../../assets/banner/undraw_circled-arrow 1.svg";
import nxt from "../../assets/banner/solar_arrow-up-broken.svg";
import "./banner.css";
import { useNavigate } from "react-router-dom";
import rr from "../../assets/banner/mouse.svg";
import we from "../../assets/banner/underline.svg";

function Banner() {
  return (
    <section id="homepage">
      <div className="banner-div">
        <img src={banner} alt="banner" className="banner-img" />
      </div>
      <Container>
        <div className="banner-text">
          Global Horizons, Beyond Borders, Boundless Opportunities: Discover
          Your Path to Success with Our Overseas Educational Solutions
        </div>
        <img src={we} className="arr" />
      </Container>
      <div className="under-arrow">
        <img src={arrow} alt="arrow" />
      </div>
      <Container>
        <div className="eqry-btn find">
          <a href="#enquire" className="str">
            <button className="now now-btn bt">
              Enquire Now
              <img src={nxt} alt="next-arrow" className="nxt-arrow nr" />
            </button>
          </a>
        </div>
        {/* <a href="/#strength"> */}
          <div id="mouse-scroll">
            <div className="mouse">
              <div className="mouse-in"></div>
            </div>
            <div className="mice">
              <span className="down-arrow-1">
                <img src={rr} className="downarrow" alt="arrow" />
              </span>
              <span className="down-arrow-2">
                <img src={rr} className="downarrow" alt="arrow" />
              </span>
              <span className="down-arrow-3">
                <img src={rr} className="downarrow" alt="arrow" />
              </span>
            </div>
          </div>
        {/* </a> */}
      </Container>
    </section>
  );
}

export default Banner;
