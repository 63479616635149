import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import nxt from "../../../../assets/banner/solar_arrow-up-broken.svg";
import eu1 from "../../../../assets/study/Europe 1.jpg";
import eu2 from "../../../../assets/study/Europe 2.jpg";
import { Container, Row, Col } from "react-bootstrap";
import "./europe.css";

function Europe(props) {
  return (
    <section id="europe">
      <div>
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="nation-modal"
        >
          <div className="nation-bg">
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <div className="nation-head">Study in Europe</div>
              <div className="nation-tag">Unleash Your Academic Potential</div>
              <div className="nation-img">
                <Container className="ctr">
                  <img src={eu1} alt="uk" className="country d1" />
                  <img src={eu2} alt="uk" className="country" />
                </Container>
              </div>
              <div className="nation-subhead">
                Are you ready to embark on a remarkable educational journey in a
                country known for its breathtaking landscapes and exceptional
                academic opportunities?
              </div>
              <div className="nation-content">
                Embark on a transformative educational journey in Europe, a
                continent renowned for its rich cultural heritage, academic
                excellence, and global opportunities. In this guide, we will
                explore the many reasons why studying in Europe is a remarkable
                choice, delve into the diverse range of educational programs,
                highlight popular student destinations, and provide essential
                information on the application process.
              </div>
              <div className="nation-subhead">Countries in Europe</div>
              <div className="nation-content">
                {/* <Row>
                  <Col lg={6} className="wd">
                    <ul className="points">
                      <div className="nation-sp">
                        <li className="f">France</li>
                        <li className="f">Germany</li>
                        <li className="f">Netherlands</li>
                        <li className="f">Sweden</li>
                        <li className="f">Austria</li>
                        <li className="f">Denmark</li>
                        <li className="f">Finland</li>
                      </div>
                    </ul>
                  </Col>
                  <Col lg={6} className="wd">
                    <ul className="points">
                      <div className="nation-sp">
                        <li className="f">Italy</li>
                        <li className="f">Hungary</li>
                        <li className="f">Switzerland</li>
                        <li className="f">Spain</li>
                        <li className="f">Lithuania</li>
                        <li className="f">Cyprus</li>
                      </div>
                    </ul>
                  </Col>
                </Row> */}
              </div>
              <div className="main">
                <div className="d1">
                  <ul className="points">
                    <div className="nation-sp">
                      <li className="f">France</li>
                      <li className="f">Germany</li>
                      <li className="f">Netherlands</li>
                      <li className="f">Sweden</li>
                      <li className="f">Austria</li>
                      <li className="f">Denmark</li>
                      <li className="f">Finland</li>
                    </div>
                  </ul>
                </div>
                <div className="d2">
                  <ul className="points">
                    <div className="nation-sp">
                      <li className="f">Italy</li>
                      <li className="f">Hungary</li>
                      <li className="f">Switzerland</li>
                      <li className="f">Spain</li>
                      <li className="f">Lithuania</li>
                      <li className="f">Cyprus</li>
                    </div>
                  </ul>
                </div>
              </div>
              <div className="why-head">Why Europe ?</div>
              <ul className="points">
                <li className="f">
                  Academic Excellence: Europe is home to world-class
                  universities and educational institutions are known for their
                  academic rigour, innovative teaching methods, and high-quality
                  programs across various fields of study.{" "}
                </li>
                <li>
                  Cultural Diversity: Studying in Europe offers a unique
                  opportunity to immerse yourself in a mosaic of cultures,
                  languages, and traditions, fostering a global mindset and
                  enriching your overall educational experience.
                </li>
                <li>
                  Accessibility and Affordability: Many European countries offer
                  affordable or even tuition-free education, making it an
                  attractive option for students looking to pursue their dreams
                  without excessive financial burden.
                </li>
                <li>
                  Multilingual Environment: Europe is a continent of languages,
                  and studying here provides an excellent chance to learn or
                  improve your language skills, whether it's English, French,
                  German, Spanish, or many other languages spoken across Europe.
                </li>
                <li>
                  Travel and Exploration: With its well-connected transportation
                  systems and geographical proximity, studying in Europe enables
                  you to easily explore neighbouring countries and embrace the
                  continent's diverse landscapes, history, and cultural
                  heritage.
                </li>
              </ul>
              <div className="universities">
                <div className="university-head">Popular Universities</div>
                <ul className="points">
                  <li className="f">Skema Business School, Paris</li>
                  <li className="f">
                    Ecole Nationale Supérieure des Mines d'Alès - IMT Mines,
                    Alès
                  </li>
                  <li className="f">Constructor University</li>

                  <li className="f">
                    SRH Berlin University of Applied Sciences, Berlin and
                    Hamburg
                  </li>
                  <li className="f">Linnaeus University, Kalmar and Växjö</li>
                  <li className="f">Aalto University, Espoo</li>
                </ul>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="nation-modal-btn">
                <Button onClick={props.onHide} className="nation-button">
                  Close
                  <img src={nxt} alt="next-arrow" className="nxt-arrow" />
                </Button>
              </div>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    </section>
  );
}

export default Europe;
