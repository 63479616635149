import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import nxt from "../../../../assets/banner/solar_arrow-up-broken.svg";
import { Container, Row, Col } from "react-bootstrap";
import canada1 from "../../../../assets/study/CANADA 1.jpg";
import canada2 from "../../../../assets/study/CANADA 2.jpg";

function Canada(props) {
  return (
    <section id="uk">
      <div>
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="nation-modal"
        >
          <div className="nation-bg">
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <div className="nation-head">Study in CANADA</div>
              <div className="nation-tag">
                Embrace a World of Possibilities{" "}
              </div>
              <div className="nation-img">
                <Container className="ctr">
                  <img src={canada1} alt="uk" className="country d1" />
                  <img src={canada2} alt="uk" className="country" />
                </Container>
              </div>
              <div className="nation-subhead">
                Are you looking for a country that offers world-class education,
                a welcoming environment, and diverse cultural experiences?
              </div>
              <div className="nation-content">
                Look no further than Canada, a top destination for international
                students seeking quality education and endless opportunities. In
                this guide, we will explore the reasons why studying in Canada
                can be a transformative experience, discuss the application
                process, and highlight some of the country's renowned
                universities.
              </div>
              <div className="why-head">Why Canada ?</div>
              <ul className="points">
                <li className="f">One of the safest countries worldwide. </li>
                <li>Practical learning experiences.</li>
                <li>Access to paid internships</li>
                <li>
                  Visa options allow post-study work for up to three years.
                </li>
                <li>Remarkable prospects for immigration.</li>
              </ul>
              <div className="cost-head">Cost of Education in Canada</div>
              <div className="cost-table">
              <table>
                  <tr>
                    <th className="cost-subhead">Expenses & Fees</th>
                    <th className="cost-subhead">Annual Expenses in USD</th>
                  </tr>
                  <tr>
                    <td className="t1">
                      {" "}
                      Tuition Fees for one-year (Indicative)*
                    </td>
                    <td className="c1">20000</td>
                  </tr>
                  <tr>
                    <td className="t2">Living and Accommodation</td>
                    <td className="c2">10200</td>
                  </tr>
                </table>
                <div className="free">* Airfare & Visa fees extra</div>
              </div>
              <div className="universities">
                <div className="university-head">Popular Universities</div>
                <ul className="points">
                  <li className="f">
                    McGill University’s School of Continuing Studies, Montreal,
                    Quebec
                  </li>
                  <li className="f">
                    Queen's University, Kingston, Ontario (Only UG)
                  </li>
                  <li className="f">
                    University of Waterloo, Waterloo, Ontario
                  </li>
                  <li className="f">
                    University of Victoria in Partnership with Kaplan, Victoria,
                    British Columbia
                  </li>
                </ul>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="nation-modal-btn">
                <Button onClick={props.onHide} className="nation-button">
                  Close
                  <img src={nxt} alt="next-arrow" className="nxt-arrow" />
                </Button>
              </div>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    </section>
  );
}

export default Canada;
