import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import nxt from "../../../../assets/banner/solar_arrow-up-broken.svg";
import aus1 from "../../../../assets/study/AUSTRALIA 1.jpg";
import aus2 from "../../../../assets/study/AUSTRALIA 2.jpg";
import { Container, Row, Col } from "react-bootstrap";

function Australia(props) {
  return (
    <section id="uk">
      <div>
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="nation-modal"
        >
          <div className="nation-bg">
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <div className="nation-head">Study in Australia</div>
              <div className="nation-tag">
                Discover the Land Down Under Study in Australia
              </div>
              <div className="nation-img">
                <Container className="ctr">
                  <img src={aus1} alt="uk" className="country d1" />
                  <img src={aus2} alt="uk" className="country" />
                </Container>
              </div>
              <div className="nation-subhead">
                Are you seeking a destination that combines high-quality
                education, breathtaking landscapes, and a vibrant multicultural
                atmosphere?
              </div>
              <div className="nation-content">
                Look no further than Australia! This guide will take you on a
                journey to explore the many benefits of studying in Australia,
                from its world-renowned universities to its welcoming
                communities and unparalleled natural wonders.
              </div>
              <div className="why-head">Why Australia ?</div>
              <ul className="points">
                <li className="f">
                  Australia is home to 7 universities ranked among the top 100
                  globally.
                </li>
                <li>
                  Explore a vast selection of over 22,000 courses offered by
                  1,100 institutions.
                </li>
                <li>
                  Experience 7 of the world's finest student cities, as
                  recognized by the QS Best Student Cities 2023 report.
                </li>
                <li>
                  International students can benefit from more than A$300
                  million invested in scholarships.{" "}
                </li>
                <li>
                  Australian universities boast a proud record of producing 15
                  Nobel Laureates.
                </li>
                <li>
                  Join a vibrant community of over half a million international
                  students from 192 countries.
                </li>
                <li>
                  Post-study work visas provide an opportunity to stay up to 6
                  years* after completing studies.
                </li>
                <li>
                  Exciting prospects for attaining permanent residency in
                  Australia.
                </li>
              </ul>
              <div className="cost-head">Cost of Education in Australia</div>
              <div className="cost-table">
                <table>
                  <tr>
                    <th className="cost-subhead">Expenses & Fees</th>
                    <th className="cost-subhead">Annual Expenses in USD</th>
                  </tr>
                  <tr>
                    <td className="t1">
                      {" "}
                      Tuition Fees for one-year (Indicative)*
                    </td>
                    <td className="c1">30000</td>
                  </tr>
                  <tr>
                    <td className="t2">Living and Accommodation</td>
                    <td className="c2">21041</td>
                  </tr>
                </table>
                <div className="free">* Airfare & Visa fees extra</div>
              </div>
              <div className="universities">
                <div className="university-head">Popular Universities</div>
                <ul className="points">
                  <li className="f">
                    The University of New South Wales, Sydney
                  </li>
                  <li className="f">The University of Queensland, Brisbane</li>
                  <li className="f">Monash University, Melbourne</li>
                  <li className="f">University of Western Australia</li>
                </ul>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="nation-modal-btn">
                <Button onClick={props.onHide} className="nation-button">
                  Close
                  <img src={nxt} alt="next-arrow" className="nxt-arrow" />
                </Button>
              </div>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    </section>
  );
}

export default Australia;
