import React from "react";
import "./home.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../header/header";
import NavBar from "../navbar/navbar";
import Banner from "../banner/banner";
import Strength from "../strength/strength";
import About from "../about/about";
import Footer from "../footer/footer";
import StudyDestination from "../destination/destination";
import Why from "../why/why";
import Testimonials from "../testimonials/testimonial";
import Enquire from "../enquire/enquire";

function Home() {
  return (
    <section id="homepage">
      <div>
        <Header />
        <NavBar />
        <Banner />
        <Strength />
        <About />
        <StudyDestination />
        <Why />
        <Testimonials />
        <Enquire />
        <Footer />
      </div>
    </section>
  );
}

export default Home;
