import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import nxt from "../../../../assets/banner/solar_arrow-up-broken.svg";
import ir1 from "../../../../assets/study/IRELAND 1.jpg";
import ir2 from "../../../../assets/study/IRELAND 2.jpg";
import { Container } from "react-bootstrap";

function Ireland(props) {
  return (
    <section id="uk">
      <div>
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="nation-modal"
        >
          <div className="nation-bg">
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <div className="nation-head">Study in Ireland</div>
              <div className="nation-tag">
                A Journey of Knowledge and Cultural Exploration
              </div>
              <div className="nation-img">
                <Container className="ctr">
                  <img src={ir1} alt="uk" className="country d1" />
                  <img src={ir2} alt="uk" className="country" />
                </Container>
              </div>
              <div className="nation-subhead">
                Are you seeking a study destination that blends academic
                excellence with a warm and welcoming atmosphere?
              </div>
              <div className="nation-content">
                Look no further than Ireland, a vibrant country renowned for its
                high-quality education system, breathtaking landscapes, and rich
                cultural heritage. In this guide, we will explore the numerous
                reasons why studying in Ireland can be a life-changing
                experience, delve into the application process, and highlight
                some of the top universities in the country.
              </div>
              <div className="why-head">Why Ireland ?</div>
              <ul className="points">
                <li className="f">Europe’s fastest-growing economy</li>
                <li>
                  Ranked #10 Globally for High-Quality Scientific Research
                </li>
                <li>Post Study Work Visa up to 2 Years</li>
                <li>All Universities Globally Ranked among the top 5%</li>
                <li>Ranked as the 13th most peaceful place on Earth .</li>
                <li>
                  Qualifications quality assured by Quality & Qualifications
                  Ireland, an Irish State Agency
                </li>
                <li>Among the top 20 education systems worldwide</li>
                <li>
                  Ireland is ranked 11th in the 2018 Global Innovation Index
                </li>
              </ul>
              <div className="cost-head">Cost of Education in Ireland</div>
              <div className="cost-table">
              <table>
                  <tr>
                    <th className="cost-subhead">Expenses & Fees</th>
                    <th className="cost-subhead">Annual Expenses in USD</th>
                  </tr>
                  <tr>
                    <td className="t1">
                      {" "}
                      Tuition Fees for one-year (Indicative)*
                    </td>
                    <td className="c1">15000</td>
                  </tr>
                  <tr>
                    <td className="t2">Living and Accommodation</td>
                    <td className="c2">10000</td>
                  </tr>
                </table>
                <div className="free">* Airfare & Visa fees extra</div>
              </div>
              <div className="universities">
                <div className="university-head">Popular Universities</div>
                <ul className="points">
                  <li className="f">Trinity College Dublin, Dublin</li>
                  <li className="f">University College Dublin, Dublin</li>
                  <li className="f">
                    The National University of Ireland Galway
                  </li>
                  <li className="f">University College Cork, Cork</li>
                </ul>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="nation-modal-btn">
                <Button onClick={props.onHide} className="nation-button">
                  Close
                  <img src={nxt} alt="next-arrow" className="nxt-arrow" />
                </Button>
              </div>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    </section>
  );
}

export default Ireland;
