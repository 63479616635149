import React from "react";
import "./strength.css";
import { Container, Row, Col } from "react-bootstrap";
import CountUp from "react-countup";

function Strength() {
  return (
    <section id="strength">
      <Container>
        <div className="sub-heading core">Our Core Strength</div>
        <Row className="counter-row">
          <Col lg={4} className="counter-col">
            <div className="counter">
              <CountUp
                end={9}
                duration={20}
                suffix="+"
                enableScrollSpy={true}
                scrollSpyDelay={100}
                scrollSpyOnce={true}
              />
            </div>
            <div className="counter-subhead">Years of Industry Experience</div>
          </Col>
          <Col lg={4} className="counter-col">
            <div className="counter">
              <CountUp
                end={2000}
                duration={10}
                suffix="+"
                enableScrollSpy={true}
                scrollSpyDelay={100}
                scrollSpyOnce={true}
              />
            </div>
            <div className="counter-subhead">Students</div>
          </Col>
          <Col lg={4} className="counter-col">
            <div className="counter">
              <CountUp
                end={100}
                duration={10}
                suffix="+"
                enableScrollSpy={true}
                scrollSpyDelay={100}
                scrollSpyOnce={true}
              />
            </div>
            <div className="counter-subhead">Global University Tie up</div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Strength;
