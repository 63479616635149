import React, { useState, useRef } from "react";
import "./modal.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Alert from "@mui/material/Alert";

function Contact(props) {
  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [question, setQuestion] = useState("");
  const [location, setLocation] = useState("");
  const [fund, setFund] = useState("");
  const [destination, setDestination] = useState("");
  const [yes, setYes] = useState("");
  const [contactError, setContactError] = useState("");

  function contactUs(event) {
    if (fname.length === 0) {
      setContactError("Please enter your first name!");
    } else if (lname.length === 0) {
      setContactError("Please enter your last name!");
    } else if (!email) {
      setContactError("Please enter a valid mail address!");
    } else if (!phone) {
      setContactError("Please enter your phone number!");
    } else if (!question) {
      setContactError("Please select a question!");
    } else if (location.length === 0) {
      setContactError("Please enter your location!");
    } else if (!fund) {
      setContactError("Please select a fund!");
    } else if (!destination) {
      setContactError("Please select a destination!");
    } else {
      let url =
        " https://api.whatsapp.com/send?phone=919778286699&text=" +
        "Hi there, this is " +
        fname +
        lname +
        "%0aEmail: " +
        email +
        "%0aMobile Number: " +
        phone +
        "%0aMy Question is Regarding: " +
        question +
        "%0aCurrent Location : " +
        location +
        "%0aEducation Funding : " +
        fund +
        "%0aPreferred Study Destination : " +
        destination +
        "%0aConsent : " +
        yes;
      // console;
      window.open(url);
    }
    setFName("");
    setLName("");
    setEmail("");
    setPhone("");
    setQuestion("");
    setLocation("");
    setFund("");
    setDestination("");
    setYes("");
    event.preventDefault();
  }

  return (
    <section id="modal">
      <div>
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="enquire-modal"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="md-head">Enquire</div>
            <div className="contact-apply">
              <div className="dat">
                <label className="contact_label">
                  First Name<span className="mandatory">*</span>
                </label>
                <div className="textfield-div">
                  <input
                    id="name"
                    type="text"
                    className="input-field"
                    required=""
                    placeholder="First Name"
                    onChange={(event) => setFName(event.target.value)}
                    // inputRef={nameInput}
                    value={fname}
                  ></input>
                </div>
              </div>
              <br></br>
              <div className="dat">
                <label className="contact_label">
                  Last Name<span className="mandatory">*</span>
                </label>
                <div className="textfield-div">
                  <input
                    id="name"
                    type="text"
                    className="input-field"
                    required=""
                    placeholder="Last Name"
                    onChange={(event) => setLName(event.target.value)}
                    // inputRef={nameInput}
                    value={lname}
                  ></input>
                </div>
              </div>
              <br></br>
              <div className="dat">
                <label className="contact_label">
                  Email<span className="mandatory">*</span>
                </label>
                <div className="textfield-div">
                  <input
                    id="email"
                    type="email"
                    className="input-field"
                    required=""
                    placeholder="Email"
                    onChange={(event) => setEmail(event.target.value)}
                    // inputRef={nameInput}
                    value={email}
                  ></input>
                </div>
              </div>
              <br></br>
              <div className="dat">
                <label className="contact_label">
                  Mobile Number<span className="mandatory">*</span>
                </label>
                <div className="textfield-div">
                  <input
                    id="number"
                    type="tel"
                    className="input-field"
                    required=""
                    placeholder="Mobile Number"
                    onChange={(event) => setPhone(event.target.value)}
                    // inputRef={nameInput}
                    value={phone}
                  ></input>
                </div>
              </div>
              <br></br>
              <div className="dat">
                <label className="contact_label">
                  My Question is Regarding<span className="mandatory">*</span>
                </label>
                <div className="textfield-div">
                  <select
                    id="cars"
                    name="cars"
                    className="input-field careerselect"
                    placeholder="Select"
                    onChange={(event) => setQuestion(event.target.value)}
                    //  inputRef={nameInput}
                    value={question}
                  >
                    <option value="Select">Select</option>
                    <option value="course">Course </option>
                    <option value="Visa">Visa </option>
                    <option value="Language Preparation">
                      Language Preparation{" "}
                    </option>
                  </select>
                </div>
              </div>
              <br></br>
              <div className="dat">
                <label className="contact_label">
                  Current Location<span className="mandatory">*</span>
                </label>
                <div className="textfield-div">
                  <input
                    id="name"
                    type="text"
                    className="input-field"
                    required=""
                    placeholder="Current Location"
                    onChange={(event) => setLocation(event.target.value)}
                    // inputRef={nameInput}
                    value={location}
                  ></input>
                </div>
              </div>
              <br></br>
              <div className="dat">
                <label className="contact_label">
                  Education Funding<span className="mandatory">*</span>
                </label>
                <div className="textfield-div">
                  <select
                    id="cars"
                    name="cars"
                    className="input-field careerselect"
                    placeholder="Select"
                    onChange={(event) => setFund(event.target.value)}
                    // inputRef={nameInput}
                    value={fund}
                  >
                    <option value="Select">Select</option>
                    <option value="I have my own money (self-funded)">
                      I have my own money (self-funded)
                    </option>
                    <option value="I am funded by parents or relatives">
                      I am funded by parents or relatives
                    </option>
                    <option value="I have a government scholarship">
                      I have a government scholarship
                    </option>
                    <option value="I am looking for a partial scholarship">
                      I am looking for a partial scholarship
                    </option>
                    <option value="I have applied for a scholarship">
                      I have applied for a scholarship
                    </option>
                    <option value="I have a student loan">
                      I have a student loan
                    </option>
                    <option value="I have applied for a student loan">
                      I have applied for a student loan
                    </option>
                    <option value="I don't have a source of funds">
                      I don't have a source of funds
                    </option>
                  </select>
                </div>
              </div>
              <br></br>
              <div className="dat">
                <label className="contact_label">
                  Preferred Study Destination
                  <span className="mandatory">*</span>
                </label>
                <div className="textfield-div">
                  <select
                    id="cars"
                    name="cars"
                    className="input-field careerselect"
                    placeholder="Select"
                    onChange={(event) => setDestination(event.target.value)}
                    // inputRef={nameInput}
                    value={destination}
                  >
                    <option value="Select">Select</option>
                    <option value="USA">USA </option>
                    <option value="UK">UK </option>
                    <option value="Canada">Canada</option>
                    <option value="Australia">Australia</option>
                    <option value="Ireland">Ireland</option>
                    <option value="New Zealand">New Zealand</option>
                    <option value="Europe">Europe</option>
                  </select>
                </div>
              </div>
              <div className="contacterror">
                {contactError && (
                  <Alert
                    variant="standard"
                    size="small"
                    severity="error"
                    className="contactalert"
                  >
                    {contactError}
                  </Alert>
                )}
              </div>
              <br></br>
              <div className="box">
                <input
                  type="checkbox"
                  id="consent"
                  name="consent"
                  onChange={(event) => setYes(event.target.value)}
                  // inputRef={nameInput}
                  value="Yes, I would like to receive information on study abroad news
                  and events from providence educational services"
                ></input>
                <label className="contact_label consent">
                  Yes, I would like to receive information on study abroad news
                  and events from Providence educational services
                </label>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="modal-btn">
              <Button onClick={contactUs} className="modal-button">
                Submit
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </section>
  );
}

export default Contact;
