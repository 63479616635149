import React from "react";
import "./about.css";
import { Container } from "react-bootstrap";

function About() {
  return (
    <section id="about">
      <Container>
        <div className="about-content">
          <div className="sub-heading about">About Us</div>
          <div className="about-data">
            A company with 9+ years of experience in providing overseas
            education and immigration services to students and professionals.
            Our team of experts helps clients with visa applications, admission
            procedures, and other relevant processes. We strive to ensure a
            hassle-free experience for our clients in achieving their study and
            career goals abroad. Our commitment is to provide students with the
            best guidance and support to achieve their academic goals.
          </div>
        </div>
      </Container>
    </section>
  );
}

export default About;
