import React from "react";
import "./destination.css";
import ring from "../../assets/study/round.svg";
import { Row, Col, Container } from "react-bootstrap";
import nxt from "../../assets/banner/solar_arrow-up-broken.svg";
import UK from "./modals/uk/uk";
import Usa from "./modals/usa/usa";
import Canada from "./modals/canada/canada";
import Australia from "./modals/australia/australia";
import Ireland from "./modals/ireland/ireland";
import Europe from "./modals/europe/europe";
import usa from "../../assets/study/usa.png";
import uk from "../../assets/study/uk.png";
import canada from "../../assets/study/canada.png";
import australia from "../../assets/study/australia.png";
import ireland from "../../assets/study/ireland.png";
import europe from "../../assets/study/europe.png";
import newzealand from "../../assets/study/europe.png";
import Newzealand from "./modals/newzealand/newzealand";

function StudyDestination() {
  const [usaModalShow, setUsaModalShow] = React.useState(false);
  const [canadaModalShow, setCanadaModalShow] = React.useState(false);
  const [ukModalShow, setUkModalShow] = React.useState(false);
  const [australiaModalShow, setAustraliaModalShow] = React.useState(false);
  const [irelandModalShow, setIrelandModalShow] = React.useState(false);
  const [europeModalShow, setEuropeModalShow] = React.useState(false);
  const [newzealandModalShow, setNewzealandModalShow] = React.useState(false);

  return (
    <section id="study-destination">
      <img src={ring} className="round-ring" alt="round-ring" />
      <div className="sub-heading study">Study Destinations</div>
      <Container>
        <Row className="destination-row">
          <Col lg={4} className="destination-col">
            <div>
              <img src={usa} alt="person" className="human" />
              <div className="study-data" onClick={() => setUsaModalShow(true)}>
                <Container>
                  <div className="study-head">
                    Study Overseas Program in the USA
                  </div>
                </Container>
                <div className="study-content">
                  Experience world-class education in the USA, renowned for its
                  prestigious universities, cutting-edge research facilities,
                  and diverse cultural experiences. Immerse yourself in a
                  vibrant academic environment, gain global perspectives, and
                  unlock a multitude of career opportunities. Expand your
                  horizons and make lifelong connections in the land of
                  innovation and academic excellence.
                </div>
                <div className="eqry-btn study-btn">
                  <button
                    className="now now-btn"
                    onClick={() => setUsaModalShow(true)}
                  >
                    View More
                    <img src={nxt} alt="next-arrow" className="nxt-arrow" />
                  </button>
                </div>
              </div>
            </div>
            <Usa show={usaModalShow} onHide={() => setUsaModalShow(false)} />
          </Col>
          <Col lg={4} className="destination-col">
            <div>
              <img src={canada} alt="person" className="human" />
              <div
                className="study-data"
                onClick={() => setCanadaModalShow(true)}
              >
                <Container>
                  <div className="study-head">
                    Study Overseas Program in Canada
                  </div>
                </Container>
                <div className="study-content">
                  Embark on an educational journey in Canada, known for its
                  welcoming environment, high-quality education, and
                  breathtaking natural landscapes. With top-ranked universities
                  and colleges, students can pursue their academic passions
                  while enjoying a multicultural society. From vibrant cities to
                  outdoor adventures, Canada offers a balanced and enriching
                  study-abroad experience.
                </div>
                <div className="eqry-btn study-btn">
                  <button
                    className="now now-btn"
                    onClick={() => setCanadaModalShow(true)}
                  >
                    View More
                    <img src={nxt} alt="next-arrow" className="nxt-arrow" />
                  </button>
                </div>
              </div>
            </div>
            <Canada
              show={canadaModalShow}
              onHide={() => setCanadaModalShow(false)}
            />
          </Col>
          <Col lg={4} className="destination-col">
            <div>
              <img src={uk} alt="person" className="human" />
              <div className="study-data" onClick={() => setUkModalShow(true)}>
                <Container>
                  <div className="study-head">
                    Study Overseas Program in the UK
                  </div>
                </Container>
                <div className="study-content">
                  Uncover centuries of academic tradition and intellectual
                  excellence in the UK. With prestigious universities, historic
                  campuses, and a reputation for academic rigour, studying in
                  the UK offers a transformative educational experience. Immerse
                  yourself in British culture, embrace diverse perspectives, and
                  thrive in a globally recognized educational system.
                </div>
                <div className="eqry-btn study-btn">
                  <button
                    className="now now-btn"
                    onClick={() => setUkModalShow(true)}
                  >
                    View More
                    <img src={nxt} alt="next-arrow" className="nxt-arrow" />
                  </button>
                </div>
              </div>
            </div>
            <UK show={ukModalShow} onHide={() => setUkModalShow(false)} />
          </Col>
          <Col lg={4} className="destination-col">
            <div>
              <img src={australia} alt="person" className="human" />
              <div
                className="study-data"
                onClick={() => setAustraliaModalShow(true)}
              >
                <Container>
                  <div className="study-head">
                    Study Overseas Program in Australia
                  </div>
                </Container>
                <div className="study-content">
                  Explore the sun-soaked shores of Australia while pursuing an
                  exceptional education. With its world-class universities,
                  innovative research opportunities, and stunning landscapes,
                  Australia captivates students from around the globe. Immerse
                  yourself in a friendly and inclusive society, discover unique
                  wildlife, and acquire knowledge that will shape your future.
                </div>
                <div className="eqry-btn study-btn">
                  <button
                    className="now now-btn"
                    onClick={() => setAustraliaModalShow(true)}
                  >
                    View More
                    <img src={nxt} alt="next-arrow" className="nxt-arrow" />
                  </button>
                </div>
              </div>
            </div>
            <Australia
              show={australiaModalShow}
              onHide={() => setAustraliaModalShow(false)}
            />
          </Col>
          <Col lg={4} className="destination-col">
            <div>
              <img src={ireland} alt="person" className="human" />
              <div
                className="study-data"
                onClick={() => setIrelandModalShow(true)}
              >
                <Container>
                  <div className="study-head">
                    Study Overseas Program in Ireland
                  </div>
                </Container>
                <div className="study-content">
                  Discover the warmth of Irish hospitality and the rich literary
                  and cultural heritage in Ireland. Home to renowned
                  universities, Ireland offers a close-knit academic community,
                  where students can thrive in a supportive and vibrant
                  environment. Explore breathtaking landscapes, embrace a lively
                  arts scene, and receive a world-class education steeped in
                  history.
                </div>
                <div className="eqry-btn study-btn">
                  <button
                    className="now now-btn"
                    onClick={() => setIrelandModalShow(true)}
                  >
                    View More
                    <img src={nxt} alt="next-arrow" className="nxt-arrow" />
                  </button>
                </div>
              </div>
            </div>
            <Ireland
              show={irelandModalShow}
              onHide={() => setIrelandModalShow(false)}
            />
          </Col>
          {/* <Col lg={4} className="destination-col">
            <div>
              <img src={newzealand} alt="person" className="human" />
              <div
                className="study-data"
                onClick={() => setNewzealandModalShow(true)}
              >
                <Container>
                  <div className="study-head">
                    Study Overseas Program in New Zealand
                  </div>
                </Container>
                <div className="study-content">
                  Embark on a journey of academic excellence amidst New
                  Zealand's stunning natural beauty. With its progressive
                  education system, cutting-edge research, and a strong focus on
                  sustainability, New Zealand offers a unique study experience.
                  Immerse yourself in a welcoming culture, adventure through
                  breathtaking landscapes, and gain a global perspective in this
                  Pacific paradise.
                </div>
                <div className="eqry-btn study-btn">
                    <button
                      className="now now-btn"
                      onClick={() => setNewzealandModalShow(true)}
                    >
                      View More
                      <img src={nxt} alt="next-arrow" className="nxt-arrow" />
                    </button>
                </div>
              </div>
            </div>
            <Newzealand
              show={newzealandModalShow}
              onHide={() => setNewzealandModalShow(false)}
            />
          </Col> */}
          <Col lg={4} className="destination-col">
            <div>
              <img src={europe} alt="person" className="human" />
              <div
                className="study-data"
                onClick={() => setEuropeModalShow(true)}
              >
                <Container>
                  <div className="study-head">
                    Study Overseas Program in Europe
                  </div>
                </Container>
                <div className="study-content">
                  Begin an exciting study abroad programme in Europe and open
                  the door to a world of possibilities. Immerse yourself in
                  different cultures, prestigious academic institutions, and
                  cutting-edge programmes customized to your specific interests.
                  Europe, with its historic cities and stunning scenery,
                  provides an enthralling setting for an enriching educational
                  experience. In this lively continent, broaden your eyes,
                  accept new perspectives, and make lifelong friendships.
                </div>
                <div className="eqry-btn study-btn">
                  <button
                    className="now now-btn"
                    onClick={() => setEuropeModalShow(true)}
                  >
                    View More
                    <img src={nxt} alt="next-arrow" className="nxt-arrow" />
                  </button>
                </div>
              </div>
            </div>
            <Europe
              show={europeModalShow}
              onHide={() => setEuropeModalShow(false)}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default StudyDestination;
