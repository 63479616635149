import React from "react";
import "./footer.css";
import { Container, Row, Col } from "react-bootstrap";
import logo from "../../assets/footer/logo.svg";
import Fb from "../../assets/footer/facebook.svg";
import Insta from "../../assets/footer/instagram.svg";
import utube from "../../assets/footer/youtube.svg";

function Footer() {
  return (
    <section id="footer">
      <div className="footer-ct">
        <Container>
          <Row className="footer-row">
            <Col lg={1}></Col>
            <Col lg={4} className="footer-col">
              <div className="footer-logo">
                <img
                  src={logo}
                  alt="logo"
                  className="footer-brand"
                  href="/#homepage"
                />
              </div>
              <div className="address">
                <a href="https://goo.gl/maps/FHTiGavsdfHzqdbZ6">
                  Providence Educational Services, 6th floor, Jomer symphony
                  tower, opposite to Zee Kerala office, Chalikavattom, vennala,
                  kochi
                </a>
              </div>
              <div className="mail">
                <a href="mailto:info@pesoverseas.com">info@pesoverseas.com</a>
              </div>
              <div className="phone">
                <a href="tel:+91 9778286699">+91 97782 86699, </a>
                <a href="tel:+91 9995476762">+91 99954 76762</a>
              </div>
            </Col>
            <Col lg={3} className="footer-col links-col">
              <div className="links">Quick Links</div>
              <p className="mt-4">
                <a href="/#homepage">Home</a>
              </p>
              <p>
                <a href="/#">Services</a>
              </p>
              <p>
                <a href="/#about">About Us</a>
              </p>
              <p>
                <a href="/#study-destination">How we do</a>
              </p>
              <p>
                <a href="/#testimonials">Portfolio</a>
              </p>
              <p>
                <a href="/#enquire">Contact Us</a>
              </p>
            </Col>
            <Col lg={3} className=" footer-media">
              <div className="ft">
                <div className="footer-media-head">Follow US</div>
                <p className="mt-4">
                  <a
                    href="https://www.facebook.com/pesoverseas"
                    class="float"
                    target="_blank"
                  >
                    <div className="f-media">
                      <img className="facebook-img" src={Fb} alt="fb" />
                    </div>
                    &nbsp; Facebook
                  </a>
                </p>
                <p>
                  <a
                    href="https://instagram.com/pes_overseas?igshid=MzRlODBiNWFlZA=="
                    class="float"
                    target="_blank"
                  >
                    <div className="f-media">
                      {" "}
                      <img className="insta-img" src={Insta} alt="insta" />
                    </div>
                    &nbsp; Instagram
                  </a>
                </p>
                <p className="utube">
                  <a
                    href="https://www.youtube.com/"
                    class="float"
                    target="_blank"
                  >
                    <div className="f-media">
                      {" "}
                      <img className="utube-img" src={utube} alt="youtube" />
                    </div>
                    &nbsp; Youtube
                  </a>
                </p>
              </div>
            </Col>
            <Col lg={1}></Col>
          </Row>
        </Container>
      </div>
      <Container>
        <div className="copyright">
          <span>
            {" "}
            Copyright &copy; 2023 All Rights Reserved Providence Educational Services
          </span>
          <br></br>
          Powered By{" "}
          <a href="https://nthindex.com/">Nthindex Software Solutions LLP</a>
        </div>
      </Container>
    </section>
  );
}

export default Footer;
