import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import nxt from "../../../../assets/banner/solar_arrow-up-broken.svg";
import usa1 from "../../../../assets/study/USA 1.jpg";
import usa2 from "../../../../assets/study/USA 2.jpg";
import { Container } from "react-bootstrap";

function Usa(props) {
  return (
    <section id="uk">
      <div>
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="nation-modal"
        >
          <div className="nation-bg">
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <div className="nation-head">Study in USA</div>
              <div className="nation-tag">
                Unlocking Boundless Opportunities
              </div>
              <div className="nation-img">
                <Container className="ctr">
                  {" "}
                  <img src={usa1} alt="uk" className="country d1" />
                  <img src={usa2} alt="uk" className="country" />
                </Container>
              </div>
              <div className="nation-subhead">
                Are you dreaming of pursuing higher education in a vibrant and
                intellectually stimulating environment?
              </div>
              <div className="nation-content">
                Look no further than the United States of America, a country
                renowned for its world-class educational institutions,
                cutting-edge research facilities, and diverse cultural
                experiences. In this guide, we will delve into the reasons why
                studying in the USA can be a life-changing experience, explore
                the application process, and showcase some of the top
                universities across the nation.
              </div>
              <div className="why-head">Why USA ?</div>
              <ul className="points">
                <li className="f">
                  The USA is home to over one million international students.
                </li>
                <li>
                  More than a quarter of the world's top 100 universities are
                  located in the USA.
                </li>
                <li>
                  STEM program graduates can benefit from post-study stay-back
                  visas (OPT) lasting up to three years.
                </li>
                <li>
                  While studying, students have the opportunity to engage in
                  internships (CPT) for a duration of up to 12 months.
                </li>
                <li>
                  Various scholarships are available based on merit and
                  financial need.
                </li>
                <li>
                  There is a vast selection of over 4,500 accredited
                  universities and institutions to choose from.
                </li>
                <li>
                  According to the QS Best Student Cities 2023 report, the USA
                  boasts 12 of the world's best student cities.
                </li>
                <li>
                  Students can explore research, teaching, and graduate
                  assistantship opportunities.
                </li>
              </ul>
              <div className="cost-head">Cost of Education in USA</div>
              <div className="cost-table">
              <table>
                  <tr>
                    <th className="cost-subhead">Expenses & Fees</th>
                    <th className="cost-subhead">Annual Expenses in USD</th>
                  </tr>
                  <tr>
                    <td className="t1">
                      {" "}
                      Tuition Fees for one-year (Indicative)*
                    </td>
                    <td className="c1">25000</td>
                  </tr>
                  <tr>
                    <td className="t2">Living and Accommodation</td>
                    <td className="c2">15000</td>
                  </tr>
                </table>
                <div className="free">* Airfare & Visa fees extra</div>
              </div>
              <div className="universities">
                <div className="university-head">Popular Universities</div>
                <ul className="points">
                  <li className="f">
                    University of Connecticut, Connecticut (Public Ivy - Only
                    UG)
                  </li>
                  <li className="f">
                    Johns Hopkins University, Baltimore, Maryland (School of
                    Engineering – Only PG){" "}
                  </li>
                  <li className="f">
                    Arizona State University, Phoenix, Arizona
                  </li>
                  <li className="f">
                    University of California, Riverside, California (Graduate
                    Business Programs & College of Engineering and UCR
                    Extension){" "}
                  </li>
                </ul>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="nation-modal-btn">
                <Button onClick={props.onHide} className="nation-button">
                  Close
                  <img src={nxt} alt="next-arrow" className="nxt-arrow" />
                </Button>
              </div>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    </section>
  );
}

export default Usa;
