import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "./uk.css";
import uk1 from "../../../../assets/study/UK 1.jpg";
import uk2 from "../../../../assets/study/UK 2.jpg";
import nxt from "../../../../assets/banner/solar_arrow-up-broken.svg";
import { Container, Row, Col } from "react-bootstrap";

function UK(props) {
  return (
    <section id="uk">
      <div>
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="nation-modal"
        >
          <div className="nation-bg">
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <div className="nation-head">Study in UK</div>
              <div className="nation-tag">
                Embark on a Journey of Academic Excellence
              </div>
              <div className="nation-img">
                <Container className="ctr">
                  {" "}
                  <img src={uk1} alt="uk" className="country d1" />
                  <img src={uk2} alt="uk" className="country" />
                </Container>
              </div>
              <div className="nation-subhead">
                Are you ready to immerse yourself in a rich academic tradition,
                vibrant culture, and diverse community?
              </div>
              <div className="nation-content">
                The United Kingdom (UK) offers a remarkable educational
                experience that combines world-class universities, innovative
                research opportunities, and a global perspective. In this guide,
                we will explore why studying in the UK is a transformative
                choice, highlight the application process, and showcase some of
                the top universities that make the UK a coveted destination for
                international students.
              </div>
              <div className="why-head">Why UK ?</div>
              <ul className="points">
                <li className="f">
                  UK contributes to 5% of global scientific research and
                  produces 14% of highly cited academic papers.
                </li>
                <li>
                  {" "}
                  More than 400,000 students choose the UK as their study
                  destination annually.
                </li>
                <li>
                  {" "}
                  A post-study work visa of two years is available for
                  international graduates.
                </li>
                <li>
                  The UK boasts 12 universities among the world's top 100
                  according to the QS World Rankings 2023.{" "}
                </li>
                <li>
                  {" "}
                  With 14 cities featured in the QS Best Student Cities 2023
                  ranking, the UK offers some of the best student cities
                  globally.{" "}
                </li>
                <li>
                  The UK is home to 131 internationally renowned universities
                  for students to choose from.{" "}
                </li>
                <li>
                  Admission without the requirement of an IELTS score is
                  possible in some cases.
                </li>

                <li>
                  {" "}
                  Students can pursue master's courses that include one-year
                  work placements.
                </li>
              </ul>
              <div className="cost-head">Cost of Education in UK</div>
              <div className="cost-table">
                <table>
                  <tr>
                    <th className="cost-subhead">Expenses & Fees</th>
                    <th className="cost-subhead">Annual Expenses in USD</th>
                  </tr>
                  <tr>
                    <td className="t1">
                      {" "}
                      Tuition Fees for one-year (Indicative)*
                    </td>
                    <td className="c1">14000</td>
                  </tr>
                  <tr>
                    <td className="t2">Living and Accommodation</td>
                    <td className="c2">9207</td>
                  </tr>
                </table>
                <div className="free">* Airfare & Visa fees extra</div>
              </div>
              <div className="universities">
                <div className="university-head">Popular Universities</div>
                <ul className="points">
                  <li className="f">University of Bristol, Bristol, England</li>
                  <li className="f">
                    University of Glasgow, Glasgow, Scotland
                  </li>
                  <li className="f">
                    University of Birmingham, Birmingham, England
                  </li>
                  <li className="f">
                    University of Southampton, Southampton, England
                  </li>
                </ul>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="nation-modal-btn">
                <Button onClick={props.onHide} className="nation-button">
                  Close
                  <img src={nxt} alt="next-arrow" className="nxt-arrow" />
                </Button>
              </div>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    </section>
  );
}

export default UK;
