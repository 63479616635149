import React, { useState } from "react";
import "./enquire.css";
import ua from "../../assets/banner/underline.svg";
import { Container, Row, Col } from "react-bootstrap";
import nxt from "../../assets/banner/solar_arrow-up-broken.svg";
import arrows from "../../assets/enquire/undraw_arched-arrow 1.svg";
import Contact from "../../components/modal/modal";

function Enquire(props) {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <section id="enquire">
        <Container>
          <div className="sub-heading enquire-head">Let's Enquire</div>
          <div className="ui">
            <img src={ua} className="uarrow" alt="underline" />
          </div>
          <Row className="enq-row">
            <Col lg={7}></Col>
            <Col lg={5}>
              <div className="enq-head">Speak with our Experts</div>
              <div className="enq-data">
                Just enter your information, and a member of our skilled team
                will contact you to schedule a FREE consultation.
              </div>
              <div className="enqr-btn">
                <button
                  className="now now-btn"
                  onClick={() => setModalShow(true)}
                >
                  ENQUIRE NOW
                  <img src={nxt} alt="next-arrow" className="nxt-arrow" />
                </button>
              </div>
              <div className="down-arrow">
                <img src={arrows} className="down-ar" alt="down-arrow" />
              </div>
            </Col>
          </Row>
          <Contact show={modalShow} onHide={() => setModalShow(false)} />
        </Container>
    </section>
  );
}

export default Enquire;
