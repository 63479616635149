import React from "react";
import "./why.css";
import logo from "../../assets/why/logo.svg";
import { Container, Row, Col } from "react-bootstrap";
import map from "../../assets/why/counseling.svg";
import turf from "../../assets/why/application.svg";
import build from "../../assets/why/visa.svg";
import visa from "../../assets/why/allied.svg";

function Why() {
  return (
    <section id="why">
      <Container>
        <Row className="why-row">
          <Col lg={5}>
            <div className="why-col">
              <div className="head">Why</div>
              <div className="logo">
                <img src={logo} className="why-logo" alt="brand" />
              </div>
            </div>
          </Col>
          <Col lg={7}>
            <div className="rd">
              <div className="why-data">
                We understand that the effectiveness of any advanced technology
                relies on the individuals operating it. That's why we offer not
                just proficient phone support but also personalized counseling
                assistance, along with an array of additional support services.
              </div>
              <div className="icons">
                  <div className="fig fi-lap">
                    <Col lg={3} className="icons-col">
                      <div className="icon-bg">
                        <img src={map} alt="map" className="map-icon" />
                      </div>
                      <div className="ic">Counseling</div>
                    </Col>
                    <Col lg={3} className="icons-col">
                      <div className="icon-bg">
                        <img src={turf} alt="turf" className="turf-icon" />
                      </div>
                      <div className="ic">
                        Application
                        <br />& Admission
                      </div>
                    </Col>
                    <Col lg={3} className="icons-col">
                      <div className="icon-bg">
                        <img src={build} alt="build" className="build-icon" />
                      </div>
                      <div className="ic">Visa Processing</div>
                    </Col>
                    <Col lg={3} className="icons-col">
                      <div className="icon-bg">
                        <img src={visa} alt="visa" className="visa-icon" />
                      </div>
                      <div className="ic">Allied Services</div>
                    </Col>
                  </div>
                  <div className="fi fi-mobile">
                  <div className="two">
                    <div className="icons-col">
                      <div className="icon-bg">
                        <img src={map} alt="map" className="map-icon" />
                      </div>
                      <div className="ic">Counseling</div>
                    </div>
                    <div className="icons-col">
                      <div className="icon-bg">
                        <img src={turf} alt="turf" className="turf-icon" />
                      </div>
                      <div className="ic">
                        Application
                        <br />& Admission
                      </div>
                    </div>
                    </div>
                    <div className="two">
                    <div className="icons-col">
                      <div className="icon-bg">
                        <img src={build} alt="build" className="build-icon" />
                      </div>
                      <div className="ic">Visa Processing</div>
                    </div>
                    <div className="icons-col">
                      <div className="icon-bg">
                        <img src={visa} alt="visa" className="visa-icon" />
                      </div>
                      <div className="ic">Allied Services</div>
                    </div>
                    </div>
                  </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Why;
