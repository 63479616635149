import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./testimonial.css";
import { Row, Col, Container } from "react-bootstrap";
import male from "../../assets/testimonials/male.svg";
import female from "../../assets/testimonials/female.svg";
import jQuery from "jquery";

function Testimonials() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    pc: {
      breakpoint: { max: 3000, min: 1910 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 1900, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section id="testimonials">
        <div className="sub-heading">Testimonials</div>
        <div className="max">
          <Carousel
            responsive={responsive}
            loop
            wrap={true}
            ride={true}
            infinite={true}
            // autoPlay={true}
          >
            <div className="cr-item">
              <div className="abc">
                <div className="cr1">
                  <Row>
                    <Col lg={8}>
                      <Container>
                        <div className="cr-data">
                          "Studying abroad was a dream come true for me, but I
                          was worried about the challenges that lay ahead.
                          Thankfully, the support I received from this overseas
                          website made all the difference. Their team provided
                          expert guidance, answering all my questions and
                          ensuring a smooth transition. From helping me with
                          visa applications to finding suitable accommodations,
                          they had my back every step of the way. I am now
                          happily settled in my chosen university, thanks to
                          their unwavering assistance. Highly recommended!"
                        </div>
                        <div className="dp dp1">
                          <div className="fr">
                            <img src={male} alt="dp" className="dp-img" />
                          </div>
                        </div>
                        <div className="designation">Abin Hermas</div>
                      </Container>
                    </Col>
                    <Col lg={1}></Col>
                    <Col lg={3} className="name-col">
                      <div className="name">MBBS Candidate, 2018</div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>

            <div className="cr-item">
              <div className="abc">
                <div className="cr2">
                  <Row>
                    <Col lg={7}>
                      <Container>
                        <div className="cr-data">
                          "I couldn't have asked for a better experience with an
                          overseas website. Their dedicated phone support and
                          one-on-one counseling assistance were invaluable
                          throughout my journey of studying abroad. The team
                          went above and beyond to understand my aspirations and
                          help me select the right university and program. They
                          guided me through the application process, offering
                          valuable insights and tips. With their unwavering
                          support, I overcame any hurdles I faced and am now
                          thriving in my international academic adventure. I
                          can't thank them enough!"
                        </div>
                        <div className="dp">
                          <div className="fr">
                            <img
                              src={female}
                              alt="dp"
                              className="dp-img female"
                            />
                          </div>
                        </div>
                        <div className="designation">Vyshnavi</div>
                      </Container>
                    </Col>
                    <Col lg={2}></Col>
                    <Col lg={3} className="name-col">
                      <div className="name">MBBS Candidate, 2018</div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>

            <div className="cr-item">
              <div className="abc">
                <div className="cr3">
                  <Row>
                    <Col lg={7}>
                      <Container>
                        <div className="cr-data">
                          "I was initially overwhelmed by the idea of studying
                          overseas, but this overseas website proved to be a
                          lifeline. Their exceptional phone support and array of
                          support services made the entire process a breeze.
                          From choosing the right destination to sorting out the
                          documentation, their knowledgeable team was there to
                          assist me at every turn. I particularly appreciated
                          their personalized counseling sessions, where they
                          understood my unique needs and helped me make informed
                          decisions. Thanks to them, I'm now enjoying a
                          fulfilling educational experience abroad. Trustworthy
                          and dependable, I highly recommend their services!"
                        </div>
                        <div className="dp">
                          <div className="fr">
                            <img
                              src={female}
                              alt="dp"
                              className="dp-img female"
                            />
                          </div>
                        </div>
                        <div className="designation">Riya Sebastian</div>
                      </Container>
                    </Col>
                    <Col lg={2}></Col>
                    <Col lg={3} className="name-col">
                      <div className="name">MBBS Candidate, 2017</div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Carousel>
        </div>
    </section>
  );
}

export default Testimonials;
